import React, { lazy, Suspense } from "react";
import {
  BrowserRouter,
  generatePath,
  Navigate,
  Route,
  Routes,
  useParams
} from "react-router-dom";
import { FluentProvider } from "@fluentui/react-components";
import {
  cargoFluentDarkTheme,
  fluentProviderStyleOverrides,
  InstrumentationProvider,
  Loading
} from "@klaveness/design";
import { RouteTracking, reportError, reportBug, event } from "instrumentation";
import ShipmentRoutes from "shipment/Routes";

// TODO: 2023-08-02 michaeljohansen: Remove this with prejudice after 2023-11-01.
if (localStorage.strings !== undefined) {
  localStorage.featureFlags = localStorage.strings;
  localStorage.removeItem("strings");
}

const Schedule = lazy(() => import("shipment"));
const ReduxApp = lazy(() => import("./ReduxApp"));

const trackUiEvent = (
  description: string,
  params?: Record<string, string | undefined>
) => event("User Interface", description, params);

export const App = () => (
  <InstrumentationProvider
    reportError={reportError}
    reportBug={reportBug}
    trackEvent={trackUiEvent}
  >
    <FluentProvider
      theme={cargoFluentDarkTheme}
      style={fluentProviderStyleOverrides}
    >
      <Suspense fallback={<Loading />}>
        <BrowserRouter>
          <Routes>
            <Route path="schedule/*" element={<Schedule />} />

            {/* These redirects are legacy, and are only added to accomodate old bookmarks for the users */}
            <Route path="redirect/schedule" element={<Schedule />} />
            <Route
              path="redirect/schedule/:id"
              element={<TemporaryShipmentRedirect />}
            />
            <Route
              path="shipment/create"
              element={<Navigate to={ShipmentRoutes.CREATE} replace />}
            />

            <Route path="*" element={<ReduxApp />} />
          </Routes>
          <RouteTracking />
        </BrowserRouter>
      </Suspense>
    </FluentProvider>
  </InstrumentationProvider>
);

const TemporaryShipmentRedirect = () => {
  const { id } = useParams();
  return (
    <Navigate
      to={id ? generatePath(ShipmentRoutes.SHIPMENT, { id }) : "/"}
      replace
    />
  );
};
