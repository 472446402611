/**
 * This is a prettified version of the Planhat integration script.
 */
const plantrack: Plantrack = [] as any;

(["init", "identify", "track"] as const).forEach(key => {
  plantrack[key] = function () {
    plantrack.push([key].concat(Array.prototype.slice.call(arguments, 0)));
  };
});

plantrack.load = function (endpoint: string, overload?: unknown) {
  plantrack._endpoint = endpoint;

  if (overload) {
    plantrack.init(overload);
  }

  var script = document.createElement("script");
  script.type = "text/javascript";
  script.async = true;
  script.src = "https://app.planhat.com/analytics/plantrack.min.js";

  document.head.append(script);
};

// Types just inferred from the code - change as appropriate
interface Plantrack extends Array<unknown[]> {
  _endpoint?: string;

  load(endpoint: string, a?: Record<string, unknown>): void;
  init(overload: unknown): void;
  identify(id: string, extras: Record<string, string>): void;
  track(): void;
}

(window as any).plantrack = plantrack;

export default plantrack;
