import { Middleware } from "redux";
import apm from "./elasticrum";
import { useSelector } from "react-redux";

export function useTracedSelector<TState, TSelected>(
  name: string,
  selector: (state: TState) => TSelected
): TSelected {
  const span = apm.startSpan(name, "redux-selector");
  const result = useSelector<TState, TSelected>(selector);
  span?.end();

  return result;
}

export const apmMiddleware: Middleware = () => next => action => {
  const span = apm.startSpan(action.type, "redux-action");
  const result = next(action);
  span?.end();

  return result;
};
