import axios from "axios";
import * as env from "utils/env";

// Axios response middleware that redirects to the login page on 401.
export const useLogoutInterceptor = () => {
  axios.interceptors.response.use(null, error => {
    if (error.response && error.response.status === 401) {
      window.location.replace(env.loginPageUrl());

      // This promise never resolves and thus "suspends" the application
      return new Promise(() => {});
    }

    return Promise.reject(error);
  });
};

// Add a more descriptive error message to Axios errors
export const useErrorInterceptor = () =>
  axios.interceptors.response.use(null, error => {
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }
    const {
      message,
      config: { method, url },
      response
    } = error;

    // Unsecurity may provide a more detailed message
    const detail = response && response.data && response.data.detail;
    const suffix = detail ? ` (${detail})` : "";

    error.message = `Response code outside 200 range for ${method.toUpperCase()} ${url}: ${message}${suffix}`;

    return Promise.reject(error);
  });

// Axios request middleware that sets the XSRF token header.
export const useTokenInterceptor = () => {
  axios.interceptors.request.use(config => {
    config.headers["x-xsrf-token"] = tokenCookieValue();
    return config;
  });
};

export const forceDefaultContentType = () => {
  axios.interceptors.request.use(config => {
    if (
      ["post", "patch", "put", "delete"].includes(
        config.method.toLowerCase()
      ) &&
      config.data === undefined
    ) {
      // The default adapter removes the "Content-Type" header if `data` is undefined
      config.headers["Content-Type"] = "application/json";
      config.data = null;
    }

    return config;
  });
};

// Extract the XSRF token from its cookie.
export const tokenCookieValue = () => {
  return document.cookie.replace(
    /(?:(?:^|.*;\s*)xsrf-token\s*=\s*([^;]*).*$)|^.*$/,
    "$1"
  );
};
