import { webDarkTheme, Theme } from "@fluentui/react-components";
import * as colors from "../../styles/colors";

/**
 * Usage:
 * ```jsx
 *   <FluentProvider theme={cargoDarkTheme} style={fluentProviderStyleOverrides}>
 *     your app here
 *   </FluentProvider>
 * ```
 *
 * @see https://react.fluentui.dev/?path=/docs/theme-color--page
 */
export const cargoFluentDarkTheme: Theme = {
  //
  // Base
  //

  ...webDarkTheme,

  //
  // Tokens (overrides)
  //

  // Colors, https://react.fluentui.dev/?path=/docs/theme-colors--page
  colorNeutralForeground1: colors.pureWhite, // "#ffffff",
  colorNeutralForeground1Hover: colors.pureWhite, // "#ffffff",
  colorNeutralForeground1Pressed: colors.pureWhite, // "#ffffff",
  colorNeutralForeground1Selected: colors.pureWhite, // "#ffffff",
  colorNeutralForeground2: colors.pureWhite60, // "#d6d6d6",
  colorNeutralForeground2Hover: colors.pureWhite, // "#ffffff",
  colorNeutralForeground2Pressed: colors.pureWhite60, // "#ffffff",
  colorNeutralForeground2Selected: colors.pureWhite, // "#ffffff",
  colorNeutralForeground2BrandHover: colors.pureWhite, // "#479ef5",
  colorNeutralForeground2BrandPressed: colors.oceanBlue, // "#2886de",
  colorNeutralForeground2BrandSelected: colors.blueGreen, // "#479ef5", // Transparent toggle button in toggled state
  // colorNeutralForeground3: "#adadad",
  // colorNeutralForeground3Hover: "#d6d6d6",
  // colorNeutralForeground3Pressed: "#d6d6d6",
  // colorNeutralForeground3Selected: "#d6d6d6",
  colorNeutralForeground3BrandHover: colors.blueGreen, // "#479ef5",
  colorNeutralForeground3BrandPressed: colors.oceanBlue, // "#2886de",
  colorNeutralForeground3BrandSelected: colors.blueGreen, // "#479ef5",
  // colorNeutralForeground4: "#999999",
  // colorNeutralForegroundDisabled: "#5c5c5c",
  // colorNeutralForegroundInvertedDisabled: "rgba(255,255,255,0.4)",
  colorBrandForegroundLink: colors.oceanBlue, // "#479ef5",
  colorBrandForegroundLinkHover: colors.blueLight, // "#62abf5",
  colorBrandForegroundLinkPressed: colors.oceanBlue, // "#2886de",
  colorBrandForegroundLinkSelected: colors.oceanBlue, // "#479ef5",
  // colorNeutralForeground2Link: "#d6d6d6",
  colorNeutralForeground2LinkHover: colors.pureWhite, // "#ffffff",
  colorNeutralForeground2LinkPressed: colors.pureWhite, // "#ffffff",
  colorNeutralForeground2LinkSelected: colors.pureWhite, // "#ffffff",
  colorCompoundBrandForeground1: colors.blueGreen, // "#479ef5",
  colorCompoundBrandForeground1Hover: colors.greenClay, // "#62abf5",
  colorCompoundBrandForeground1Pressed: colors.oceanBlue, // "#2886de",
  colorBrandForeground1: colors.blueGreen, // "#479ef5",
  colorBrandForeground2: colors.greenClay, // "#62abf5",
  // colorNeutralForeground1Static: "#242424",
  colorNeutralForegroundStaticInverted: colors.pureWhite, // "#ffffff",
  colorNeutralForegroundInverted: colors.pureWhite, // "#242424",
  // colorNeutralForegroundInvertedHover: "#242424",
  // colorNeutralForegroundInvertedPressed: "#242424",
  // colorNeutralForegroundInvertedSelected: "#242424",
  // colorNeutralForegroundInverted2: "#242424",
  colorNeutralForegroundOnBrand: colors.pureWhite, // "#ffffff",
  colorNeutralForegroundInvertedLink: colors.pureWhite, // "#ffffff",
  colorNeutralForegroundInvertedLinkHover: colors.pureWhite, // "#ffffff",
  colorNeutralForegroundInvertedLinkPressed: colors.pureWhite, // "#ffffff",
  colorNeutralForegroundInvertedLinkSelected: colors.pureWhite, // "#ffffff",
  colorBrandForegroundInverted: colors.blueGreen, // "#479ef5",
  colorBrandForegroundInvertedHover: colors.greenClay, // "#62abf5",
  colorBrandForegroundInvertedPressed: colors.blueGreen, // "#479ef5",
  colorBrandForegroundOnLight: colors.greenClay, // "#0f6cbd",
  // colorBrandForegroundOnLightHover: "#115ea3",
  // colorBrandForegroundOnLightPressed: "#0e4775",
  // colorBrandForegroundOnLightSelected: "#0f548c",
  colorNeutralBackground1: colors.lightBlueClay, // "#292929", // Background color added to FluentProvider
  colorNeutralBackground1Hover: colors.blueClay, // "#3d3d3d", // TODO: Not great, see secondary toggle button when toggled
  colorNeutralBackground1Pressed: colors.lightBlueClay, // "#1f1f1f",
  colorNeutralBackground1Selected: colors.oceanBlue, // "#383838",
  colorNeutralBackground2: colors.blueClay, // "#1f1f1f",
  // colorNeutralBackground2Hover: "#333333",
  // colorNeutralBackground2Pressed: "#141414",
  colorNeutralBackground2Selected: colors.blueClay, // "#2e2e2e",
  // colorNeutralBackground3: "#141414",
  // colorNeutralBackground3Hover: "#292929",
  // colorNeutralBackground3Pressed: "#0a0a0a",
  // colorNeutralBackground3Selected: "#242424",
  // colorNeutralBackground4: "#0a0a0a",
  colorNeutralBackground4Hover: colors.blueClay, // "#1f1f1f",
  // colorNeutralBackground4Pressed: "#000000",
  // colorNeutralBackground4Selected: "#1a1a1a",
  // colorNeutralBackground5: "#000000",
  // colorNeutralBackground5Hover: "#141414",
  // colorNeutralBackground5Pressed: "#050505",
  // colorNeutralBackground5Selected: "#0f0f0f",
  // colorNeutralBackground6: "#333333",
  colorNeutralBackgroundInverted: colors.pureWhite, // "#ffffff",
  // colorNeutralBackgroundStatic: "#3d3d3d",
  colorSubtleBackground: "transparent",
  colorSubtleBackgroundHover: colors.blueClay, // "#383838",
  colorSubtleBackgroundPressed: colors.lightBlueClay, // "#2e2e2e",
  colorSubtleBackgroundSelected: colors.oceanBlue, // "#333333", // TODO: Not great, see subtle toggle button in toggled state
  // colorSubtleBackgroundLightAlphaHover: "rgba(36,36,36,0.8)",
  // colorSubtleBackgroundLightAlphaPressed: "rgba(36,36,36,0.5)",
  colorSubtleBackgroundLightAlphaSelected: "transparent",
  colorSubtleBackgroundInverted: "transparent",
  // colorSubtleBackgroundInvertedHover: "rgba(0,0,0,0.1)",
  // colorSubtleBackgroundInvertedPressed: "rgba(0,0,0,0.3)",
  // colorSubtleBackgroundInvertedSelected: "rgba(0,0,0,0.2)",
  colorTransparentBackground: "transparent",
  colorTransparentBackgroundHover: "transparent",
  colorTransparentBackgroundPressed: "transparent",
  colorTransparentBackgroundSelected: "transparent",
  colorNeutralBackgroundDisabled: colors.greyDisabled, // "#141414",
  // colorNeutralBackgroundInvertedDisabled: "rgba(255,255,255,0.1)",
  // colorNeutralStencil1: "#575757",
  // colorNeutralStencil2: "#333333",
  // colorBackgroundOverlay: "rgba(0,0,0,0.5)",
  // colorScrollbarOverlay: "rgba(255,255,255,0.6)",
  colorBrandBackground: colors.blueGreen, // "#115ea3", // Button primary and such
  colorBrandBackgroundHover: colors.greenClay, // "#0f6cbd",
  colorBrandBackgroundPressed: colors.blueGreen, // "#0c3b5e",
  colorBrandBackgroundSelected: colors.oceanBlue, // "#0f548c", // TODO: Not great, see primary toggle button when toggled
  colorCompoundBrandBackground: colors.blueGreen, // "#479ef5",
  colorCompoundBrandBackgroundHover: colors.greenClay, // "#62abf5",
  colorCompoundBrandBackgroundPressed: colors.oceanBlue, // "#2886de",
  colorBrandBackgroundStatic: colors.greenClay, // "#0f6cbd",
  colorBrandBackground2: colors.blueGreen, // "#0c3b5e",
  colorBrandBackgroundInverted: colors.pureWhite, // "#ffffff",
  // colorBrandBackgroundInvertedHover: "#ebf3fc",
  // colorBrandBackgroundInvertedPressed: "#b4d6fa",
  // colorBrandBackgroundInvertedSelected: "#cfe4fa",
  // colorNeutralStrokeAccessible: "#adadad",
  // colorNeutralStrokeAccessibleHover: "#bdbdbd",
  // colorNeutralStrokeAccessiblePressed: "#b3b3b3",
  colorNeutralStrokeAccessibleSelected: colors.blueGreen, // "#479ef5",
  colorNeutralStroke1: colors.lightBlueClay, // "#666666", // Button secondary border and such
  colorNeutralStroke1Hover: colors.blueClay, // "#757575",
  colorNeutralStroke1Pressed: colors.lightBlueClay, // "#6b6b6b",
  colorNeutralStroke1Selected: colors.blueGreen, // "#707070",
  // colorNeutralStroke2: "#525252",
  // colorNeutralStroke3: "#3d3d3d",
  // colorNeutralStrokeOnBrand: "#292929",
  colorNeutralStrokeOnBrand2: colors.pureWhite, // "#ffffff",
  colorNeutralStrokeOnBrand2Hover: colors.pureWhite, // "#ffffff",
  colorNeutralStrokeOnBrand2Pressed: colors.pureWhite, // "#ffffff",
  colorNeutralStrokeOnBrand2Selected: colors.pureWhite, // "#ffffff",
  colorBrandStroke1: colors.blueGreen, // "#479ef5",
  // colorBrandStroke2: "#0e4775",
  colorCompoundBrandStroke: colors.blueGreen, // "#479ef5",
  colorCompoundBrandStrokeHover: colors.greenClay, // "#62abf5",
  colorCompoundBrandStrokePressed: colors.oceanBlue, // "#2886de",
  colorNeutralStrokeDisabled: colors.greyDisabled, // "#424242",
  // colorNeutralStrokeInvertedDisabled: "rgba(255,255,255,0.4)",
  colorTransparentStroke: "transparent",
  colorTransparentStrokeInteractive: "transparent",
  colorTransparentStrokeDisabled: "transparent",
  // colorStrokeFocus1: "#000000",
  colorStrokeFocus2: colors.pureWhite, // "#ffffff",
  // colorNeutralShadowAmbient: "rgba(0,0,0,0.24)",
  // colorNeutralShadowKey: "rgba(0,0,0,0.28)",
  // colorNeutralShadowAmbientLighter: "rgba(0,0,0,0.12)",
  // colorNeutralShadowKeyLighter: "rgba(0,0,0,0.14)",
  // colorNeutralShadowAmbientDarker: "rgba(0,0,0,0.40)",
  // colorNeutralShadowKeyDarker: "rgba(0,0,0,0.48)",
  // colorBrandShadowAmbient: "rgba(0,0,0,0.30)",
  // colorBrandShadowKey: "rgba(0,0,0,0.25)",

  // Font families, https://react.fluentui.dev/?path=/docs/theme-typography--page
  fontFamilyBase: "Roboto, sans-serif",
  // fontFamilyMonospace: ,
  // fontFamilyNumeric: ,

  // Font sizes, https://react.fluentui.dev/?path=/docs/theme-typography--page
  fontSizeBase100: "0.625rem", // Originally 10px;
  fontSizeBase200: "0.750rem", // Originally 12px;
  fontSizeBase300: "0.875rem", // Originally 14px;
  fontSizeBase400: "1.000rem", // Originally 16px;
  fontSizeBase500: "1.250rem", // Originally 20px;
  fontSizeBase600: "1.500rem", // Originally 24px;
  fontSizeHero700: "1.750rem", // Originally 28px;
  fontSizeHero800: "2.000rem", // Originally 32px;
  fontSizeHero900: "2.500rem", // Originally 40px;
  fontSizeHero1000: "4.25rem", // Originally 68px;

  // Font weights, https://react.fluentui.dev/?path=/docs/theme-typography--page
  // fontWeightRegular: ,
  // fontWeightMedium: ,
  // fontWeightSemibold: ,
  // fontWeightBold: ,

  // Line heights, https://react.fluentui.dev/?path=/docs/theme-typography--page
  lineHeightBase100: "1.35", // Originally 14px; which corresponded to 1.4
  lineHeightBase200: "1.35", // Originally 16px; which corresponded to 1.33
  lineHeightBase300: "1.35", // Originally 20px; which corresponded to 1.4
  lineHeightBase400: "1.35", // Originally 22px; which corresponded to .375
  lineHeightBase500: "1.35", // Originally 28px; which corresponded to 1.4
  lineHeightBase600: "1.35", // Originally 32px; which corresponded to 1.33
  lineHeightHero700: "1.35", // Originally 36px; which corresponded to 1.28
  lineHeightHero800: "1.35", // Originally 40px; which corresponded to 1.25
  lineHeightHero900: "1.35", // Originally 52px; which corresponded to 1.3
  lineHeightHero1000: "1.35", // Originally 92px; which corresponded to 1.35

  // Typography styles, https://react.fluentui.dev/?path=/docs/theme-typography--page
  // body1: ,
  // body1Strong: ,
  // body1Stronger: ,
  // body2: ,
  // caption1: ,
  // caption1Strong: ,
  // caption1Stronger: ,
  // caption2: ,
  // caption2Strong: ,
  // subtitle1: ,
  // subtitle2: ,
  // subtitle2Stronger: ,
  // title1: ,
  // title2: ,
  // title3: ,
  // largeTitle: ,
  // display: ,
};

/**
 * `<FluentProvider>` takes the liberty to set background-color, font-family and
 * various other completely unwanted properties on its own component. This is
 * undesirable because a "Provider" should just provide context, variables,
 * props or event management, - it should not render a visible element.
 *
 * “Bit annoying really…” - Hermione Granger
 */
export const fluentProviderStyleOverrides = {
  // Don't allow FluentProvider to mess with background and foreground color
  backgroundColor: "unset",
  color: "unset",
  // Don't allow FluentProvider to mess with all fonts
  fontFamily: "unset",
  fontSize: "unset",
  fontWeight: "unset",
  lineHeight: "unset",
};
