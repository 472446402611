/**
 * All inventory routes are defined in the inventory router. So why does *this*
 * list exist you may ask? This list only exists because we need to use React
 * Router's `generatePath()` to make absolute routes when navigating around.
 */
// prettier-ignore
enum Routes {
  CONTRACTS = "/contracts",
}

export default Routes;
