import { identify as identifyAnalytics } from "./analytics";
import { init as initUsersnap } from "./usersnap";

export {
  event,
  debouncedEvent,
  updateCompany,
  pageView,
  init
} from "./analytics";
export { reportError } from "./errors";
export { default as RouteTracking } from "./RouteTracking";
export { apmMiddleware, useTracedSelector } from "./redux";
export { open as reportBug } from "./usersnap";

export const identify = (
  id: string,
  email: string,
  company: string,
  companyName: string
) => {
  identifyAnalytics(id, email, company, companyName);
  initUsersnap({
    user: {
      userId: id,
      email
    }
  });
};
