import { useEffect } from "react";
import { matchRoutes, useLocation } from "react-router-dom";
import { pageView } from "./analytics";
import dashboardRoutes from "dashboard/Dashboard/Routes";
import shipmentRoutes from "shipment/Routes";
import inventoryRoutes from "components/Inventory/Routes";
import demurrageRoutes from "demurrage/Routes";
import insightRoutes from "insights/Routes";
import contractsRoutes from "contracts/Routes";
import lineupRoutes from "../execution/Routes";

/**
 * _No title? No tracking!_
 *
 * A route that is tracked is given a friendly title. If there is no title there
 * will be no tracking. Simple as that.
 */
// prettier-ignore
const prefixToTitle = {
  // Dashboard
  "/dashboard/:portId": "Dashboard",
  // Inventory
  "/inventory/:inventoryId/details/:from/:until": "Inventory Details",
  "/inventory/inventories/:inventoryId/details": "Inventory Details",
  "/inventory/configure": "Configure Inventory",
  "/inventory/overview/:date": "Inventory Overview",
  "/inventory/overview/yearly/:year": "Inventory Overview Year",

  "/inventory/tree": "Inventory Tree",
  "/inventory/aggregation/:aggregationId/details/:from/:until": "Aggregation Details",
  "/scenarios": "Scenarios",
  "/inventory/:inboundOrOutbound/:terminalId/:materialId/configure": "Configure Inventory (Legacy)",
  "/inventory/:inboundOrOutbound/:terminalId/:materialId/details": "Inventory Details (Legacy)",
  "/inventory/:inboundOrOutbound/:terminalId/:materialId/levels": "Adjust Inventory Levels (Legacy)",
  "/inventory/:inboundOrOutbound/:terminalId/:materialId/calibrate": "Calibrate Inventory (Legacy)",
  "/inventory/:inboundOrOutbound/:terminalId/:materialId/planner": "Inventory Schedule Optimizer (Legacy)",
  // Demurrage
  "/demurrage/:year": "Demurrage Manager",
  // Port Insights
  "/insights/overview": "Port Insights Overview",
  "/insights/details/:page/:portId": "Port Insights Details",
  "/insights/vessels/:imo": "Vessel Insights Details",
  // Shipment
  // Note: A temporary solution for certain shipment routes exists. See usage of `useTemporaryRouteTracking`.
  "/schedule/create": "Create Shipment",
  "/schedule/lineup": "Lineup Manager",
  //Contracts
  "/contracts":"Contracts",
  "/suppliers":"Suppliers",
  // Lineup
  "/lineup/overview": "Lineup Overview",
  "/lineup/etamanager": "ETA Manager",
};

/**
 * Takes a route path, returns a friendly title.
 *
 * If there are multiple possible matching routes then the longest route path
 * will be assumed to be the correct one.
 *
 * @param route Route path, like "/inventory/overview/:year"
 * @returns Friendly title, like "Inventory Overview Year"
 */
const getTitle = (route: string): string | null => {
  const prefixes = (
    Object.keys(prefixToTitle) as (keyof typeof prefixToTitle)[]
  ).filter(prefix => route?.startsWith(prefix));

  if (prefixes.length === 0) return null;

  const prefix = prefixes.reduce((acc, curr) => {
    if (curr.length > acc.length) return curr;
    return acc;
  });
  return prefixToTitle[prefix];
};

const dashboard = (
  Object.keys(dashboardRoutes) as (keyof typeof dashboardRoutes)[]
).map(key => ({ path: dashboardRoutes[key] }));
const shipment = (
  Object.keys(shipmentRoutes) as (keyof typeof shipmentRoutes)[]
).map(key => ({ path: shipmentRoutes[key] }));
const inventory = (
  Object.keys(inventoryRoutes) as (keyof typeof inventoryRoutes)[]
).map(key => ({ path: inventoryRoutes[key] }));
const contracts = (
  Object.keys(contractsRoutes) as (keyof typeof contractsRoutes)[]
).map(key => ({ path: contractsRoutes[key] }));
const demurrage = (
  Object.keys(demurrageRoutes) as (keyof typeof demurrageRoutes)[]
).map(key => ({ path: demurrageRoutes[key] }));
const insight = (
  Object.keys(insightRoutes) as (keyof typeof insightRoutes)[]
).map(key => ({ path: insightRoutes[key] }));
const lineup = (Object.keys(lineupRoutes) as (keyof typeof lineupRoutes)[]).map(
  key => ({ path: lineupRoutes[key] })
);
/**
 * Make a map of all known routes that we are interested in tracking.
 *
 * `matchRoutes()` is unable to match routes with query params (a link with one
 * or more hashtags `#something=something`. So we remove query params from all
 * paths before matching.
 */
const allRoutes = [
  ...dashboard,
  ...shipment,
  ...inventory,
  ...contracts,
  ...demurrage,
  ...insight,
  ...lineup
].map(route => {
  if (route.path.includes("#")) {
    const hashIndex = route.path.indexOf("#");
    return { ...route, path: route.path.substring(0, hashIndex) };
  }
  return route;
});

/**
 * RouteTracking tries to match the current route with all known paths and
 * figures out which one we are at. If that path has a known `title` then we log
 * a `pageView()` to Woopra.
 *
 * TODO: Certain pages trigger double or triple pageView()'s due to redirects or
 *       other conditions. Find and fix double renders.
 */
const RouteTracking = (): null => {
  const matches = matchRoutes(allRoutes, useLocation());
  if (matches !== null && matches.length > 1) {
    // We'll write an error to the console, but not worth doing anything more
    // dramatic since route tracking is just nice-to-have.
    console.error(
      "RouteTracking: multiple routes matched, this has not been handled yet",
      matches
    );
  }
  const route = matches?.[0].route.path ?? undefined;

  const location = useLocation();
  const constructedHref = location.pathname;

  useEffect(() => {
    if (route !== undefined) {
      const title = getTitle(route);
      if (title !== null) {
        pageView(route, constructedHref, title);
      }
    }
  }, [route, constructedHref]);

  return null;
};

export default RouteTracking;
