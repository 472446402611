enum Routes {
  SCHEDULED = "/schedule/scheduled",
  SCHEDULED_SHIPMENT = "/schedule/scheduled/:id",
  DELIVERED = "/schedule/delivered",
  DELIVERED_SHIPMENT = "/schedule/delivered/:id",
  UNIFIED = "/schedule/ports",
  UNIFIED_PORT = "/schedule/ports/:portId",
  TIMELINE = "/schedule/timeline",
  LINEUP = "/schedule/lineup",
  CREATE = "/schedule/create",
  SHIPMENT = "/schedule/shipments/:id"
}

export default Routes;
