import React from "react";
import classnames from "classnames";
import styles from "./styles.module.scss";

type Props = {
  className?: string;
};

const Spinner = (props: Props) => (
  <div className={classnames(styles.spinner, props.className)}>
    <span style={{ opacity: 0 }}>Loading...</span>
  </div>
);

export default Spinner;
