import React from "react";
import "core-js/stable";
import ReactDOM from "react-dom"; // React 17 mode
// import { createRoot } from "react-dom/client"; // React 18 mode
import ReactModal from "react-modal";
import smoothscroll from "smoothscroll-polyfill";

import "@klaveness/design/main.scss";
import { App } from "components/App";
import * as http from "utils/http";
import { init as initInstrumentation } from "instrumentation";
import { init as initChat } from "utils/chat";

// Initialize instrumentation (woopra/elastic rum)
initInstrumentation();

// Redirect any 401 response to the Auth0 login page.
http.useLogoutInterceptor();

// Throw all response errors as exceptions.
http.useErrorInterceptor();

// Set an XSRF token header on every request.
http.useTokenInterceptor();

// Axios doesn't send content type on empty requests by default, but this is
// required by the backend
http.forceDefaultContentType();

// Apply scrolling polyfill for Edge browser.
smoothscroll.polyfill();

// Load chat widget (note: depends on axios config)
initChat();

// Reload on chunk errors (after deploy)
window.addEventListener(
  "unhandledrejection",
  (event: PromiseRejectionEvent) => {
    if (event.reason && event.reason.name === "ChunkLoadError") {
      window.location.reload();
      event.stopImmediatePropagation();
    }
  }
);

// Render the root component.
const root = document.getElementById("root"); // React 17 mode
const el = root as HTMLElement; // React 17 mode
ReactModal.setAppElement(el); // React 17 mode
ReactDOM.render(<App />, el); // React 17 mode
// const container = document.getElementById("root")!; // React 18 mode
// ReactModal.setAppElement(container); // React 18 mode
// const root = createRoot(container); // React 18 mode
// root.render(<App />); // React 18 mode
