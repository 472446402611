let resolveApi: (r: UsersnapApi) => void;
const api: Promise<UsersnapApi> = new Promise(r => (resolveApi = r));

const CALLBACK_NAME = "onUsersnapCXLoad";

/**
 * Open the bug reporting interface.
 */
export const open = () => api.then(api => api.logEvent("report_bug"));

/**
 * Load and initialize Usersnap
 */
export const init = (options: InitOptions) => {
  if ((window as any)[CALLBACK_NAME]) {
    // We've already initialized UserSnap
    return;
  }

  // Will be called by usersnap script when loaded
  (window as any)[CALLBACK_NAME] = (api: UsersnapApi) => {
    api.init(options);
    resolveApi(api);
  };

  const script = document.createElement("script");
  script.defer = true;
  script.src = `https://widget.usersnap.com/global/load/${process.env.REACT_APP_USERSNAP_KEY}?onload=${CALLBACK_NAME}`;
  document.getElementsByTagName("head")[0].appendChild(script);
};

/**
 * This is the API provided by the Usersnap script (at least a part of it)
 */
interface UsersnapApi {
  init(options?: InitOptions): void;
  logEvent(eventName: string): void;

  // We haven't typed these yet, but they apparently exist
  on: unknown;
  off: unknown;
  destroy: unknown;
  show: unknown;
  hide: unknown;
}

interface InitOptions {
  custom?: Record<string, string | number | boolean>;
  user?: {
    userId?: string;
    email?: string;
  };
}
