class Woopra {
  private window: any;

  constructor(window: any) {
    this.window = window;
  }

  /**
   * Track a custom action
   *
   * @param eventName The name of the event that you are tracking.
   * @param properties An object of any properties you want to track with the event
   * @param callback A callback function to run after the tracking function has been successfully received by Woopra
   */
  track(eventName: string, properties?: object, callback?: () => void) {
    this.window.woopra.track(eventName, properties, callback);

    if (process.env.NODE_ENV === "development") {
      console.groupCollapsed(
        ` %cwoopra%c %s`,
        "color: gray; font-weight: lighter;",
        "color: initial; font-weight: bold;",
        eventName === "pv" ? "pv (page view)" : eventName
      );
      console.table(properties);
      console.groupEnd();
    }
  }

  reportError(message: string) {
    this.track("error", { message });
  }

  identify(properties: object) {
    this.window.woopra.identify(properties);
  }

  pageview(url: string, title: string, uri: string) {
    this.track("pv", { url, title, uri });
  }
}

const woopra = new Woopra(window);
export default woopra;

/**
 * This is a prettified version of Woopra's tracking snippet. They have promised
 * their implementation won't change.
 */
function WoopraAperitif(this: any) {
  const methods = [
    "config",
    "track",
    "identify",
    "visit",
    "push",
    "call",
    "trackForm",
    "trackClick"
  ];

  // This is picked up by the woopra script when loaded
  this._e = [];

  for (let index = 0; methods.length > index; index++) {
    const method = methods[index];
    this[method] = (...args: any[]) => {
      this._e.push([method, ...args]);
      return this;
    };
  }
}

// @ts-ignore
const woopraAperitif = new WoopraAperitif();
const w = window as any;
w._w = w._w || {};
w._w["woopra"] = w["woopra"] = woopraAperitif;

export const configureWoopra = () => {
  const domain = woopraDomain();

  if (domain) {
    w.woopra.config({ domain, protocol: "https" });

    const scriptElement = document.createElement("script");
    scriptElement.async = true;
    scriptElement.src = "https://static.woopra.com/js/w.js";

    document.head.appendChild(scriptElement);
  }
};

const woopraDomain = () =>
  window.location.hostname === process.env.REACT_APP_PROD_DOMAIN
    ? "cargovalue.com"
    : null;
