import { reportError as reportErrorToAnalytics } from "./analytics";
import apm from "./elasticrum";

/**
 * Reports an error to all our various tracking solutions.
 */
export const reportError = (error: Error) => {
  let success = true;

  console.error(error);

  try {
    reportErrorToAnalytics(error.toString());
  } catch {
    success = false;
  }

  try {
    apm.captureError(error);
  } catch {
    success = false;
  }

  return success;
};
