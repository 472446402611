import React, { FunctionComponent } from "react";
import styles from "./styles.module.scss";
import classnames from "classnames";
import Spinner from "./Spinner";

interface Props {
  isModal?: boolean;
  inComponent?: boolean;
}

export const Loading: FunctionComponent<Props> = (props) => (
  <div
    role="alert"
    aria-busy="true"
    aria-live="polite"
    className={classnames(
      styles.loading,
      props.isModal && styles.loadingModal,
      props.inComponent && styles.inComponent
    )}
  >
    <Spinner />
  </div>
);

export { Spinner };
