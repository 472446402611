/**
 * All inventory routes are defined in the inventory router. So why does *this*
 * list exist you may ask? This list only exists because we need to use React
 * Router's `generatePath()` to make absolute routes when navigating around.
 */
// prettier-ignore
enum Routes {
  CONFIGURE_INVENTORY = "/inventory/configure/:materialId/:portId/:bound",

  INVENTORY_DETAILS =  "/inventory/:inventoryId/details",
  INVENTORY_RANGE =    "/inventory/:inventoryId/details/:from/:until",
  INVENTORY_SHIPMENT = "/inventory/:inventoryId/details/:from/:until/:shipmentId",

  OVERVIEW =      "/inventory/overview",
  OVERVIEW_DATE = "/inventory/overview/:date",
  OVERVIEW_YEAR = "/inventory/overview/yearly/:year",

  TREE =      "/inventory/tree",
  TREE_DATE = "/inventory/tree/:date",
  TREE_NODE = "/inventory/tree/:date/:zoomToNodeId",

  AGGREGATION_DETAILS =   "/inventory/aggregation/:aggregationId/details",
  AGGREGATION_RANGE =     "/inventory/aggregation/:aggregationId/details/:from/:until",
  AGGREGATION_INVENTORY = "/inventory/aggregation/:aggregationId/details/:from/:until/:inventoryId",
  AGGREGATION_SHIPMENT =  "/inventory/aggregation/:aggregationId/details/:from/:until/:inventoryId/:shipmentId/",

  SCENARIOS = "/scenarios/:snapshotId/:scenarioId#year-:year#month-:month",

  INVENTORY_REDIRECT =          "/inventory/redirect/:inventoryId",
  INVENTORY_SHIPMENT_REDIRECT = "/inventory/redirect/:inventoryId/:shipmentId",

  LEGACY_CONFIGURE =    "/inventory/:inboundOrOutbound/:terminalId/:materialId/configure",
  LEGACY_DETAILS =      "/inventory/:inboundOrOutbound/:terminalId/:materialId/details",
  LEGACY_DETAILS_YEAR = "/inventory/:inboundOrOutbound/:terminalId/:materialId/details/:year",
  LEGACY_LEVELS =       "/inventory/:inboundOrOutbound/:terminalId/:materialId/levels",
  LEGACY_CALIBRATE =    "/inventory/:inboundOrOutbound/:terminalId/:materialId/calibrate",
  LEGACY_PLANNER =      "/inventory/:inboundOrOutbound/:terminalId/:materialId/planner",
  LEGACY_PLANNER_YEAR = "/inventory/:inboundOrOutbound/:terminalId/:materialId/planner/:year",
}

export default Routes;
