import constants from "./constants.module.scss";

//
// `_constants.scss` is the source of truth, this file is just for making the
// colors available in TypeScript. `constants.module.scss` is also necessary.
//

// Primary accent colors
export const blueGreen = constants.blueGreen;
export const red = constants.red;
export const yellow = constants.yellow;
export const oceanBlue = constants.oceanBlue;

// Secondary accent colors
export const greenClay = constants.greenClay;
export const lightBlueClay = constants.lightBlueClay;
export const purpleAgg = constants.purpleAgg;
export const silo = constants.silo;
export const clay = constants.clay;
export const blueLight = constants.blueLight;

// Neutral main colors
export const mainBg = constants.mainBg;
export const deepSeaBlue = constants.deepSeaBlue;
export const blueClay = constants.blueClay;
export const greyDisabled = constants.greyDisabled;
export const greyEditable = constants.greyEditable;
export const grey = constants.grey;

// Text colors
export const pureWhite = constants.pureWhite;
export const pureWhite60 = constants.pureWhite60;
export const pureWhite40 = constants.pureWhite40;

// Various graphic elements from the BrandPad
export const green = constants.green;
export const greenBright = constants.greenBright;
export const greenLight = constants.greenLight;

// Gradient colors
export const group1 = {
  color1: constants.gr1_1,
  color2: constants.gr1_2,
  color3: constants.gr1_3,
  color4: constants.gr1_4,
  color5: constants.gr1_5,
  color6: constants.gr1_6,
  color7: constants.gr1_7,
  color8: constants.gr1_8,
  color9: constants.gr1_9,
  color10: constants.gr1_10,
};
export const group2 = {
  color1: constants.gr2_1,
  color2: constants.gr2_2,
  color3: constants.gr2_3,
  color4: constants.gr2_4,
  color5: constants.gr2_5,
  color6: constants.gr2_6,
  color7: constants.gr2_7,
  color8: constants.gr2_8,
  color9: constants.gr2_9,
  color10: constants.gr2_10,
};
export const group3 = {
  color1: constants.gr3_1,
  color2: constants.gr3_2,
  color3: constants.gr3_3,
  color4: constants.gr3_4,
  color5: constants.gr3_5,
  color6: constants.gr3_6,
  color7: constants.gr3_7,
  color8: constants.gr3_8,
  color9: constants.gr3_9,
  color10: constants.gr3_10,
};
export const group4 = {
  color1: constants.gr4_1,
  color2: constants.gr4_2,
  color3: constants.gr4_3,
  color4: constants.gr4_4,
  color5: constants.gr4_5,
  color6: constants.gr4_6,
  color7: constants.gr4_7,
  color8: constants.gr4_8,
  color9: constants.gr4_9,
  color10: constants.gr4_10,
};
export const group5 = {
  color1: constants.gr5_1,
  color2: constants.gr5_2,
  color3: constants.gr5_3,
  color4: constants.gr5_4,
  color5: constants.gr5_5,
  color6: constants.gr5_6,
  color7: constants.gr5_7,
  color8: constants.gr5_8,
  color9: constants.gr5_9,
  color10: constants.gr5_10,
};
export const group6 = {
  color1: constants.gr6_1,
  color2: constants.gr6_2,
  color3: constants.gr6_3,
  color4: constants.gr6_4,
  color5: constants.gr6_5,
  color6: constants.gr6_6,
  color7: constants.gr6_7,
  color8: constants.gr6_8,
  color9: constants.gr6_9,
  color10: constants.gr6_10,
};
export const group7 = {
  color1: constants.gr7_1,
  color2: constants.gr7_2,
  color3: constants.gr7_3,
  color4: constants.gr7_4,
  color5: constants.gr7_5,
  color6: constants.gr7_6,
  color7: constants.gr7_7,
  color8: constants.gr7_8,
  color9: constants.gr7_9,
  color10: constants.gr7_10,
};
export const group8 = {
  color1: constants.gr8_1,
  color2: constants.gr8_2,
  color3: constants.gr8_3,
  color4: constants.gr8_4,
  color5: constants.gr8_5,
  color6: constants.gr8_6,
  color7: constants.gr8_7,
  color8: constants.gr8_8,
  color9: constants.gr8_9,
  color10: constants.gr8_10,
};
export const group9 = {
  color1: constants.gr9_1,
  color2: constants.gr9_2,
  color3: constants.gr9_3,
  color4: constants.gr9_4,
  color5: constants.gr9_5,
  color6: constants.gr9_6,
  color7: constants.gr9_7,
  color8: constants.gr9_8,
  color9: constants.gr9_9,
  color10: constants.gr9_10,
};
export const group10 = {
  color1: constants.gr10_1,
  color2: constants.gr10_2,
  color3: constants.gr10_3,
  color4: constants.gr10_4,
  color5: constants.gr10_5,
  color6: constants.gr10_6,
  color7: constants.gr10_7,
  color8: constants.gr10_8,
  color9: constants.gr10_9,
  color10: constants.gr10_10,
};
export const group11 = {
  color1: constants.gr11_1,
  color2: constants.gr11_2,
  color3: constants.gr11_3,
  color4: constants.gr11_4,
  color5: constants.gr11_5,
  color6: constants.gr11_6,
  color7: constants.gr11_7,
  color8: constants.gr11_8,
  color9: constants.gr11_9,
  color10: constants.gr11_10,
};
export const group12 = {
  color1: constants.gr12_1,
  color2: constants.gr12_2,
  color3: constants.gr12_3,
  color4: constants.gr12_4,
  color5: constants.gr12_5,
  color6: constants.gr12_6,
  color7: constants.gr12_7,
  color8: constants.gr12_8,
  color9: constants.gr12_9,
  color10: constants.gr12_10,
};

// Color groups
export const chartColors = constants.chartColors.split(/[ ,/]/);
export const aggregationColors = constants.aggregationColors.split(/[ ,/]/);
export const inventoryColors = constants.inventoryColors.split(/[ ,/]/);
