import React, { FunctionComponent, PropsWithChildren, useMemo } from "react";
import Context, { InstrumentationContext } from "./Context";

type Props = PropsWithChildren<Partial<InstrumentationContext>>;

const Provider: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  trackEvent,
  reportBug,
  reportError,
}) => {
  const context = useMemo<InstrumentationContext>(
    () => ({
      trackEvent: trackEvent ?? (() => {}),
      reportError,
      reportBug,
    }),
    [trackEvent, reportBug, reportError]
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export default Provider;
