import woopra, { configureWoopra } from "./woopra";
import apm from "./elasticrum";
import plantrack from "./planhat";

/**
 * We want to send some properties with every following event.
 */
let woopraProps: WoopraProps = {};
let lastMutedErrorReport = 0;

const MUTED_ERROR_MIN_INTERVAL = 5000; // ms

interface WoopraProps {
  company?: string;
  url?: string;
  title?: string;
  companyName?: string;
}

export const init = () => {
  configureWoopra();

  // Load Planhat (CRM integration)
  plantrack.load(
    `https://analytics.planhat.com/analytics/${process.env.REACT_APP_PLANHAT_TOKEN}`
  );

  // Forward errors to tracking solution
  window.addEventListener("error", errorEvent => {
    reportError(errorEvent ? errorEvent.message : "Unknown error");
  });

  window.addEventListener("unhandledrejection", event => {
    reportError(event.reason ? event.reason.toString() : "Unknown error");
  });
};

export const reportError = (message: string) => {
  /**
   * Some errors are muted for security reasons and will in that case (according
   * to the spec) have the "Script error." message.
   *
   * These may be triggered by the Woopra error reporting, so we're checking
   * this condition to avoid an endless loop.
   */
  if (message === "Script error.") {
    const now = Date.now();

    if (now - lastMutedErrorReport < MUTED_ERROR_MIN_INTERVAL) {
      return;
    }

    lastMutedErrorReport = now;
  }

  woopra.reportError(message);
};

// these tools are global, so we store state globally :(
let hasIdentified = false;

export const identify = (
  id: string,
  email: string,
  company: string,
  companyName: string
) => {
  if (hasIdentified) {
    return;
  }

  hasIdentified = true;
  const domain = email.split("@")[1];
  woopraProps.company = company;
  woopraProps.companyName = companyName;
  woopra.identify({ "Email domain": domain, company, id, email, companyName });
  woopra.track("login", {
    "Email domain": domain,
    company,
    id,
    email,
    companyName
  });

  plantrack.identify(id, { email });

  apm.addLabels({
    "user.id": id,
    company
  });
  apm.setUserContext({ id });
};

export const updateCompany = (company: string) => {
  woopraProps.company = company;
  apm.addLabels({ company });
  event("Application", "Change company");
};

type CustomProps = { [k: string]: string | number | undefined };

export const event = (
  category: string,
  action: string = "default",
  properties?: CustomProps
) => {
  woopra.track(action, { ...properties, category: category, ...woopraProps });
};

interface EventType {
  category: string;
  action: string;
}

interface DebouncingEvents {
  event: EventType;
  timeout: number;
}

let debouncingEvents: DebouncingEvents[] = [];

const isSameEventType = (a: EventType, b: EventType): boolean =>
  a.action === b.action && a.category === b.category;

export const debouncedEvent = (
  category: string,
  action: string = "default",
  properties?: CustomProps,
  wait: number = 1000
) => {
  const e: EventType = { category, action };

  const timeout = window.setTimeout(() => {
    debouncingEvents = debouncingEvents.filter(
      de => !isSameEventType(de.event, e)
    );
    event(category, action, properties);
  }, wait);

  const previous = debouncingEvents.find(de => isSameEventType(de.event, e));
  if (previous) {
    window.clearTimeout(previous.timeout);
    previous.timeout = timeout;
  } else {
    debouncingEvents.push({ event: e, timeout });
  }
};

export const pageView = (page: string, location: string, title: string) => {
  woopraProps.url = page;
  woopraProps.title = title;

  woopra.pageview(page, title, location);
};
