import { createContext } from "react";

/**
 * The design module allows its consumers to implement these methods in order to
 * track information captured by the design module itself.
 */
export interface InstrumentationContext {
  /**
   * Track a generic user interface event (such as switching tabs).
   */
  trackEvent(
    description: string,
    params?: Record<string, string | undefined>
  ): void;

  /**
   * Request the bug reporting interface to be opened.
   *
   * This interface should allow our user to report the bug, possibly even with
   * a screenshot of the app.
   */
  reportBug?(): void;

  /**
   * Silently report an error to our monitoring/analytics solutions.
   *
   * This will typically be errors caught inside error boundaries.
   *
   * Should return `true` if the report operation was successful.
   */
  reportError?(error: Error): boolean;
}

const Context = createContext<InstrumentationContext>({
  trackEvent() {},
});

export default Context;
