export const isLocalhost = () => {
  return window.location.hostname === "localhost";
};

export const isCI = () => {
  return window.location.hostname === "cargo-frontend";
};

export const loginPageUrl = () => {
  const apiOrigin = window.location.origin;

  return (
    `/api/cargo/login?next=${encodeURIComponent(window.location.href)}` +
    `&auth0Callback=${encodeURIComponent(`${apiOrigin}/api/cargo/callback`)}`
  );
};

export const logoutUrl = () => {
  return "/api/cargo/logout";
};

export const apiPath = () => {
  return "/api/cargo";
};

export const analyticsPath = () => {
  if (window.location.hostname === "app.cargovalue.com") {
    return "https://analytics.cargovalue.com/";
  } else if (window.location.hostname === "localhost") {
    return "http://localhost:3100/analytics";
  } else {
    return "/analytics";
  }
};

export const emissionsPath = () => {
  if (window.location.hostname === "app.cargovalue.com") {
    return "https://emissions.cargovalue.com/";
  } else if (window.location.hostname === "localhost") {
    return "http://localhost:3100/emissions";
  } else {
    return "/emissions";
  }
};
