import { init as initApm } from "@elastic/apm-rum";

const { hostname } = window.location;

/**
 * Integration with Elasticsearch real user monitoring
 */
export default initApm({
  serviceName: "CargoValue",
  serverUrl: process.env.REACT_APP_ELASTIC_RUM_URL,
  serviceVersion: process.env.REACT_APP_VERSION || "1",
  environment: hostname,

  // Disable for e2e tests
  active: hostname !== "cargo-frontend",

  breakdownMetrics: true,

  // We're recording route changes "manually"
  disableInstrumentations: ["history"]
});
