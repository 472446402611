import axios from "axios";
import { apiPath } from "./env";
import { colors } from "@klaveness/design";
import { reportError } from "../instrumentation";

export function init() {
  (window as any).zESettings = {
    webWidget: {
      chat: {
        // We use the Usersnap feedback menu to open the chat
        suppress: true
      },
      color: {
        theme: colors.blueGreen,
        launcher: colors.blueGreen,
        launcherText: colors.pureWhite
      },
      authenticate: {
        chat: {
          jwtFn: function (callback: (jwt: string) => void) {
            axios.get<string | null>(`${apiPath()}/chattoken`).then(
              ({ data: token }) => {
                if (token) {
                  callback(token);
                }
              },
              // no full screen error messages for these errors o.O
              reportError
            );
          }
        }
      }
    }
  };

  const key = process.env.REACT_APP_ZENDESK_CHAT_KEY;

  if (!key) {
    console.error("No token defined for the Zendesk chat");
    return;
  }

  const script = document.createElement("script");
  script.src = `https://static.zdassets.com/ekr/snippet.js?key=${process.env.REACT_APP_ZENDESK_CHAT_KEY}`;
  script.id = "ze-snippet";

  document.head.appendChild(script);
}

export function toggle() {
  (window as any).zE("webWidget", "toggle");
}
